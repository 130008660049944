.dx-blazor-snackbar-container {
    z-index: 30001;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    margin: 0;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear;

    right: .5rem;
    left: .5rem;
    bottom: .5rem;

    @media (min-width: 576px) {
        width: 580px;                   //
        padding: 0 24px;                //for the consistency with toolbar modal windows

        left: calc(50% - 290px);
        right: unset;
        bottom: 1rem;
    }

    &.dx-blazor-snackbar-container-shown {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    & .dx-blazor-snackbar {
        padding: .5rem;
        margin: 0;
        width: 100%;
        opacity: .75;
    }
}
