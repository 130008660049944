@mixin active-thumb-x {
    opacity: $dx-scroll-viewer-scroll-bar-opacity;
    width: $dx-scroll-viewer-scroll-bar-hover-size;
    border-radius: $dx-scroll-viewer-scroll-bar-border-radius;
}

@mixin active-thumb-y {
    opacity: $dx-scroll-viewer-scroll-bar-opacity;
    height: $dx-scroll-viewer-scroll-bar-hover-size;
    border-radius: $dx-scroll-viewer-scroll-bar-border-radius;
}
